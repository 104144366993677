import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import utils from "../utils";

const {
  methods: { fetchApi },
} = utils;

Vue.use(Vuex);

const emptyContent = {
  id: -1,
  name: "not found",
  content: "Kon content niet laden...",
  imageGroup: {
    id: -1,
    name: "not found",
    images: [],
  },
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["user.token"],
      storage: window.sessionStorage,
    }),
  ],
  state: {
    contents: null,
    dogs: null,
    images: null,
    imageGroups: null,
    user: {
      token: null,
    },
  },
  mutations: {
    FETCH_CONTENTS(state, contents) {
      state.contents = contents;
    },
    FETCH_DOGS(state, dogs) {
      state.dogs = dogs;
    },
    FETCH_IMAGES(state, images) {
      state.images = images;
    },
    FETCH_IMAGE_GROUPS(state, imageGroups) {
      state.imageGroups = imageGroups;
    },
    SET_TOKEN(state, token) {
      state.user.token = token;
    },
  },
  actions: {
    fetchContents({ commit }) {
      return new Promise((resolve) => {
        fetchApi("contents")
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            if (res.success) {
              commit("FETCH_CONTENTS", res.data);
            }

            resolve(res);
          })
          .catch((error) => {
            resolve({ success: false, error: error.statusText });
          });
      });
    },
    fetchDogs({ commit }) {
      return new Promise((resolve) => {
        fetchApi("dogs")
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              commit("FETCH_DOGS", res.data);
            }

            resolve(res);
          })
          .catch((error) => {
            resolve({ success: false, error: error.statusText });
          });
      });
    },
    fetchImages({ commit }) {
      return new Promise((resolve) => {
        fetchApi("images")
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              commit("FETCH_IMAGES", res.data);
            }

            resolve(res);
          })
          .catch((error) => {
            resolve({ success: false, error: error.statusText });
          });
      });
    },
    fetchImageGroups({ commit }) {
      return new Promise((resolve) => {
        fetchApi("image-groups")
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              commit("FETCH_IMAGE_GROUPS", res.data);
            }

            resolve(res);
          })
          .catch((error) => {
            resolve({ success: false, error: error.statusText });
          });
      });
    },
  },
  getters: {
    getContent: (state) => (name) => {
      const contents = state.contents;
      if (!contents) return emptyContent;

      const content = contents.find((x) => x.name.toLowerCase() === name);
      if (!content) {
        return emptyContent;
      }

      return content;
    },
    isAuthenticated(state) {
      return !!state.user.token;
    },
  },
});
